@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .App {
    text-align: center;
  }
  
  /* Navigation Bar */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    background-color: #E9EEF1;
    position: fixed;
    top: 0;
    width: 97%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .logo {
    width: 200px; /* Increased logo size */
    height: auto;
    margin-top: -15px;
    margin-bottom: -20px;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 50px; /* Increased spacing between buttons */
  }
  
  .nav-links li a.nav-btn-green {
    text-decoration: none;
    color: white;
    font-weight: bold;
    background-color: #4CAF50;
    padding: 10px 20px; /* Added more padding */
    border-radius: 25px; /* Increased border radius for rounder buttons */
    transition: background-color 0.3s, transform 0.2s ease;
  }
  
  .nav-links li a.nav-btn-green:hover {
    background-color: #557b2f;
    transform: scale(1.5);
  }
  
  .nav-links li a.nav-btn-orange {
    text-decoration: none;
    color: white;
    font-weight: bold;
    background-color: #FFA500;
    padding: 10px 20px; /* Added more padding */
    border-radius: 25px; /* Increased border radius for rounder buttons */
    transition: background-color 0.3s;
  }
  
  .nav-links li a.nav-btn-orange:hover {
    background-color: #e57a00;
  }
  
  .cta-buttons {
    display: flex;
    gap: 15px; /* Added spacing between Subscribe and Donate buttons */
  }
  
  .cta-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 25px; /* Rounded CTA buttons */
    cursor: pointer;
  }
  
  .subscribe-btn {
    background-color: #3a5d33;
    color: white;
    border: none;
    transition: background-color 0.3s;
    font-weight: bold;
  }
  
  .donate-btn {
    background-color: #ff8c00;
    display: block;
    height: auto;
    color: white;
    border: none;
    transition: background-color 0.3s;
    font-weight: bold;
  }
  
  .subscribe-btn:hover {
    background-color: #557b2f;
  }
  
  .donate-btn:hover {
    background-color: #e57a00;
  }
  
  /* Hero Section */
  .hero {
    padding: 5rem 2rem;
    background-color: #ffffff;
    margin-top: 80px; /* To avoid overlap with the fixed navbar */
  }
  
  .hero-title {
    font-size: 3rem;
    color: #4CAF50;
  }
  
  .text-orange {
    color: #FFA500; /* or use a specific hex code, like #FFA500 */
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .get-started-btn {
    padding: 15px 30px;
    background-color: #3a5d33;
    color: white;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 25px; /* Rounded Get Started button */
    transition: background-color 0.3s, transform 0.5s ease;
  }
  
  .get-started-btn:hover {
    background-color: #557b2f;
    transform: scale(1.1);
  }
  /* Mission Section */

  .mission-section {
    padding: 3rem 2rem;
    
    background-color: #f9f9f9;
  }
  h2 {
    font-size: 3rem;
    color: #3a5d33;
    margin-top: 4rem;
    
  }

  .mission-section h2 {
    font-size: 3rem;
    color: #3a5d33;
    margin-bottom: 2rem;
  }
  
  .mission-section h3 {
    font-size: 2rem;
    font-weight: normal;
    margin-right: 50px;
    margin-left: 50px;
  }
  .mission h3 {
    font-size: 1.5rem;
    font-weight: normal;
    margin-right: 200px;
    margin-left: 200px;
    line-height: 1.75;
    text-align: center;
  }



  
  
  .mission-section p {
    font-size: 1.5rem;
  }
  
  .mission-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  
  .mission-text {
    text-align: center; /* Center text horizontally */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure content fills the div */
  }
  
  .image-placeholder {
    width: 100%;
    
    padding-bottom: 70%;
    background-size: cover;
  }
  
  
  
  .orange {
    background-color: #d9772b;
  }
  
  .green {
    background-color: #3a5d33;
  }
  
  /* Smooth scrolling */
  html {
    scroll-behavior: smooth;
  }
  
  
  .bottom-right-btn {
    position: relative;
    bottom: -20px;  /* Distance from the bottom of the viewport */
    right: -675px;   /* Distance from the right of the viewport */
    background-color: transparent; /* No background, just the image */
    border: none;
    padding: 0;
    cursor: pointer;
    z-index: 100;
  }
  
  .bottom-right-btn img.arrow-icon {
    width: 80px;  /* Adjust the size as needed */
    height: 80px;
  }
  
  .bottom-right-btn:hover img.arrow-icon {
    opacity: 0.8;  /* Slight transparency on hover */
    color: none;
  }
  
  
  /* Programs Section */
  .programs-section {
    padding: 3rem 2rem;
  }
  
  .programs-section h2 {
    font-size: 3rem;
    color: #ff8c00;
    margin-bottom: 2rem;
  }
  
  .program-slider {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .program-content {
    display: flex;
    transition: transform 0.3s ease-in-out;
    gap: 0px;
  }
  
  /* Program Card */
  .program-card {
    min-width: 350px;
    margin-right: 50px;
    margin-left: 10px;
    perspective: 1000px;
    position: relative;
    display: flex; /* Ensure the card occupies space */
    justify-content: center; /* Center child elements */
    align-items: center; /* Center child elements */
    cursor: pointer; /* Change cursor to pointer for clickable effect */
    transition: transform 0.3s ease; /* Transition for scaling effect */
  }
  
  .flipped {
    transform: rotateY(180deg); /* Rotate when flipped */
  }
  
  .program-inner {
    position: relative; /* Ensure .program-inner is positioned */
    width: 100%;
    height: 625px; /* Set a fixed height for visibility */
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transform-origin: center;
  }
  
  .program-card.flipped .program-inner {
    transform: rotateY(180deg);
  }
  
  .program-front,
  .program-back {
    position: absolute; /* Overlay front and back sides */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Hide back face when not rotated */
    border-radius: 10px;
  }
  
  .program-back-orange {
    background-color: #ff8c00;
    
  }
  .program-back-green {
    background-color:  #4CAF50;
    
  }
  
  
  .program-front {
    background-color: #f9f9f9;
  }
  
  .program-card:hover {
    transform: scale(1.05); /* Enlarge card on hover */
  }
  
  .program-back {
    
    color: white;
    transform: rotateY(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  /* Program Card Image */
  .program-card img {
    max-width: 100%;
    border-radius: 10px;
  }
  
  .program-description {
    color: black; /* Text color */
    font-size: 1rem; /* Adjust font size as needed */
    line-height: 1.5; /* Line height for better readability */
  }
  
  .program-description h4{
    color: black; /* Text color */
    font-size: 1rem; /* Adjust font size as needed */
    line-height: 1.5; /* Line height for better readability */
  }
  
  .program-description p{
    color: black; /* Text color */
    font-size: 1rem; /* Adjust font size as needed */
    line-height: 1.5; /* Line height for better readability */
  }
  
  .program-heading {
    font-weight: bold; /* Make headings bold */
    color: black; /* Color for headings */
    margin-bottom: 0.5rem; /* Space below headings */
  }
  
  .program-heading h4{
    font-weight: bold; /* Make headings bold */
    color: black; /* Color for headings */
    margin-bottom: 0.5rem; /* Space below headings */
  }
  
  
  
  /* Improved Impact Section */
  .impact-section {
    padding: 3rem 0; /* Remove side padding if unnecessary */
    background-color: #f3f4f6;
    text-align: center;
  }
  
  .impact-section h2 {
    font-size: 0.75rem;
    color: #3a5d33;
    margin-bottom: 3rem;
  }
  
  .impact-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 3rem;
    max-width: 1400px;
    margin: 0 auto;
    justify-content: center; /* Center grid content horizontally */
  }
  
  .stat-box {
    background: linear-gradient(135deg, #ffffff, #e7f0e7);
    padding: 2rem;
    width: 80%; /* Take full width of grid column */
    height: 220px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-left: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .stat-box:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }
  
  .stat-box h3 {
    font-size: 3.5rem;
    color: #36335d;
    margin: 0;
  }
  
  .stat-box p {
    font-size: 1.1rem;
    color: #333333;
    margin-top: 0.5rem;
  }
  
  
  
  .founders-gallery {
    display: flex;
    justify-content: center;
    gap: 4rem;
    margin-top: 3rem; /* Added top margin */
  }
  
  .founder-card {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 8px;
    padding: 0px;
    width: 380px; /* Increased width */
    height: 540px; /* Adjusted height */
    overflow: hidden;
    border-radius: 10px;
    margin-top: 75px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    
  }
  
  .founder-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    
  }
  
  .founder-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .founder-card h4,
  .founder-card h5,
  .founder-card p {
    position: absolute;
    color: white;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -100px;
  }
  
  .founder-card h4 {
    top: 60%; /* Adjusted positioning for overlay */
    font-size: 1.8rem; /* Slightly increased font size */
  }
  
  .founder-card h5 {
    top: 80%; /* Adjusted positioning for overlay */
    font-size: 1.1rem; /* Slightly increased font size */
    width: 85%;
  }
  
  .founder-card p {
    top: 100%;
    font-size: 1.1rem; /* Increased font size */
    width: 85%;
  }
  
  
  
  .foundation-thankyou {
    margin-top: 3rem;
    display: flex; /* Use flexbox layout */
    align-items: center; /* Vertically center the items */
    justify-content: space-between; /* Space between the logo and text */
    text-align: left; /* Align text to the left */
    margin-left: 100px;
  }
  
  .thank-you-text {
    flex: 1; /* Allow text section to grow and take available space */
    margin-right: 20px; /* Add right margin for spacing */
    display: flex; /* Enable flex on the text container */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center text vertically */
    align-items: flex-start; /* Align text to the start horizontally */
    height: 100%; /* Ensure it takes full height */
  }
  
  .kauffman-logo {
    flex: 0 0 auto; /* Prevent logo from growing */
  }
  
  .foundation-thankyou img {
    width: 500px;
    margin-bottom: 1rem;
    padding: 5px;
    border-radius: 10px;
    background: linear-gradient(45deg, #32CD32, #006400);
    display: inline-block;
    background-clip: padding-box;
    transition: transform 0.3s ease; /* Smooth scaling effect */
  }
  
  /* Hover effect to scale the image slightly */
  .foundation-thankyou img:hover {
    transform: scale(1.03);
  }
  
  /* Link styling */
  .hover-link {
    position: relative;
    display: inline-block;
    text-decoration: none;
  }
  
  /* Animated line on hover */
  .hover-link::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 4px;
    border-radius: 10px;
    background-color: #32CD32; /* Color of the line */
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }
  
  /* On hover, expand the line from right to left */
  .hover-link:hover::after {
    transform: scaleX(1);
    transform-origin: left;
  }
  
  .foundation-thankyou h3 {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #557b2f;
    line-height: 1.5;
  }
  
  .foundation-thankyou p {
    margin-top: 20px;
    max-width: 600px;
    margin: 0 auto;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .green-box {
    background: linear-gradient(135deg, #ffffff, #dcf0dc);
  }
  
  .orange-box {
    background: linear-gradient(135deg, #fffaf0, #f9e1c7);
  }
  
  /* Gradient Text Colors for Numbers */
  .orange-gradient {
    background: linear-gradient(135deg, #ff8c00, #d9772b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .green-gradient {
    background: linear-gradient(135deg, #3a5d33, #88c27f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* governance section */
  .governance-section {
    padding: 40px;
    text-align: center;
  }
  
  .governance-section h2 {
    font-size: 3rem;
    color: #ff8c00;
    margin-bottom: 2rem;
  }
  
  .governance-grid {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  
  .governance-category {
    margin-bottom: 0px;
    position: relative;
  }
  
  .members {
    display: flex;
    gap: 50px;
    justify-content: center;
    flex-wrap: wrap; /* Allow wrapping to the next line */
  }
  
  .member-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .member-container:hover {
    transform: scale(1.1);
  }
  
  .member-image {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    transition: transform 0.3s ease-in-out;
    padding: 5px; /* Space for the gradient border */
    box-sizing: border-box;
    object-fit: cover;
  }
  
  .member-name {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .members p {
    font-size: 1.2rem;
    font-weight: normal;
  }
  
  /* Divider line between categories */
  .governance-category + .governance-category::before {
    content: '';
    display: block;
    width: 800px;
    height: 2px;
    background-color: #cbd3d1;
    margin: 20px auto;
  }
  
  .governance-category h3 {
    font-size: 2rem;
  }
  
  h6 {
    font-size: 1.5rem;
    font-weight: normal;
  }
  
  /* Fullscreen Popup Modal */
  .fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0s 0.3s;
    z-index: 1000;
  }
  
  .fullscreen-modal.show {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out;
  }
  
  .popup-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 400px;
    max-width: 90%;
    text-align: center;
    animation: fadeIn 0.4s ease-in-out;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
    color: #333;
  }
  
  .popup-content h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  .popup-content p {
    font-size: 16px;
    color: #666;
  }
  
  .popup-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  
  /* info*/
  .info-section {
    padding: 4rem 1rem;
    text-align: center;
    background-color: #ffffff;
    color: #333;
  }
  
  .info-section h2{
    
      font-size: 3rem;
      color: #3a5d33;
      margin-bottom: 3rem;
    
  }
  
  .info-section .subheading {
    font-size: 2rem;
    color: #14552A;
    margin-bottom: 2rem;
  }
  
  /* Grid layout for cards */
  .card-grid {
    display: grid;
    gap: 0rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: 0 auto;
  }
  
  /* Card styling */
  .card {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
    margin-right: 50px;
    width: 400px;
    margin-left: 50px;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card-img {
    width: 90%;
    height: 200px;
    object-fit: cover;
    padding: 1rem;
  }
  
  .card-body {
    padding: 1.5rem;
  }
  
  .card-title {
    font-weight: bold;
    font-size: 1.25rem;
    color: #14552A;
    margin-top: 1rem;
  }
  
  .card-text {
    font-size: 1rem;
    color: #333;
  }
  
  /* Button styling */
  .sign-up-btn {
    margin-top: 2rem;
    background-color: #ff8c00;
    color: #fff;
    border: none;
    border-radius: 25px;
    padding: 10px 25px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.5s ease;
  }
  
  .sign-up-btn:hover {
    background-color: #e57a00;
    transform: scale(1.1);
  }
  
  
  /* footer */
  /* Main Footer Styles */
  .footer {
    background-color: #f7f7f7;
    text-align: center;
    padding: 2rem;
    font-family: Arial, sans-serif;
  }
  
  .footer h2 {
    font-size: 3rem;
    color: #ff8c00;
    margin-bottom: 2rem;
  }
  
  .footer-info h2 {
    font-size: 3rem;
    color: #3a5d33;
    margin-bottom: 2rem;
  }
  
  /* Buttons in the Footer */
  .footer-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .subscribe-btn, .volunteer-btn {
    background-color: #4CAF50;
    font-weight: bold;
    color: white;
  }
  
  .volunteer-btn:hover {
    background-color: #557b2f;
  }
  
  .donate-btn {
    background-color: #FFA500;
    color: white;
    margin-top: 0px;
    
  }
  
  .subscribe-btn, .volunteer-btn, .donate-btn, .business-btn, .footer-link {
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.5s ease;
    
  }
  
  .subscribe-btn:hover, .volunteer-btn:hover, .donate-btn:hover, .business-btn:hover, .footer-link:hover {
    
    transform: scale(1.1);
  }
  
  footer.footer {
    
    padding: 0;
    padding-top: 20px;
    margin: 0;
  }
  
  
  /* Learn More Section */
  .dark-gray-bg {
    background-color: #333;
    color: white;
    padding: 1.5rem 0;
    text-align: center;
    width: 100vw; /* Make it take full viewport width */
    margin: 0; /* Remove any default margin */
    box-sizing: border-box;
  }
  /* Categories Section */
  .categories {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  
  .governance-category h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .rva-logo {
    font-size: 1.5rem;
    margin: 1.5rem 0;
    text-align: center;
  }
  
  .rva-logo p {
    font-weight: bold;
  }
  
  .rva-logo span {
    font-size: 0.9rem;
    color: #ddd;
  }
  
  /* Divider */
  .governance-category + .governance-category::before {
    content: '';
    display: block;
    width: 1px;
    background-color: #cbd3d1;
    margin: 0 auto;
  }
  
  /* Footer Links Section - Dark Gray Background */
  .footer-links-container {
    background-color: #333; /* Full dark gray background */
    padding: 1.5rem 0;
  }
  
  .footer-links {
    display: flex;
    justify-content: center;
    gap: 10rem;
    margin-top:30px;
  }
  
  .footer-link {
    background-color: #FFA500;
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin: 0 0.5rem;
    margin-right: 50px;
  }
  
  .footer-link:hover {
    background-color: #e57a00;
  }
  /* Business Page Button */
  .business-btn {
    background-color: #3a5d33;
    color: white;
    margin-bottom: 50px;
    margin-top:30px;
  }
  
  /* Copyright Section */
  .copyright {
    background-color: #333;
    color: #999;
    padding: 1rem;
    font-size: 0.8rem;
    text-align: center;
  }
  
  .partners-logos {
    width: 100%; /* Adjust to control size based on container */
    max-width: 620px; /* Limits maximum size */
    height: auto;
    margin-right: 50px;
   
  }
  
  .feature-logos {
    width: 100%; /* Adjust to control size based on container */
    max-width: 700px; /* Limits maximum size */
    height: auto;
    margin-left: 50px;
  }
  
  .footer h2 {
    
  }
  
  .secondlogo {
    width: 350px; /* Increased logo size */
    height: auto;
    margin-top: -15px;
    margin-bottom: -20px;
  }
  
  .divider {
    border-top: 2px solid #635e5e69; /* Adjust color to match the line color */
    margin: 20px auto;
    margin-top: 50px;
    width: 80%; /* Full width */
    border-radius: 20px;
  
  }
  
  /* Modal.css */
  
  /* Modal styles */
  .modal {
    display: flex;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    max-width: 500px;
    text-align: center;
    border-radius: 8px;
    position: relative;
  }
  
  .close {
    color: #aaa;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  #submitEmail {
    background-color: #E48525;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  #submitEmail:hover {
    background-color: #9DD1AB;
  }
  
  #emailInput {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  
  /* CSS for scrollable modal content */
  .popup-content {
    max-height: 80vh; /* Set maximum height to 80% of the viewport */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
  }


  /* resources */


.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 50px;
  
  background-color: #ffffff; /* Light background for contrast */
}

.resources-section {
  width: 500px; /* Increased width */
  padding: 30px;
  border: 1px solid #ccc;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.resources-section-h2 {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 20px;
  color: #ff8c00;
}

.resources-section p {
  text-align: center;
  font-size: 1rem;
  font-family: Poppins;
  margin-bottom: -10px;

}

.resources-section label {
  display: block;
  margin-top: 15px;
  font-size: 16px;
  font-family: Poppins;
  font-weight: bold;
  color: #006400; /* Dark green for label text */
  text-align: center; /* Ensures left alignment for labels */
  
}

.resources-section h3 {
  text-align: center; /* Centers the heading */
  margin-bottom: 20px; /* Adds spacing below the heading */
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 0px;
  font-family: Poppins;
  
  
}


.resources-section input[type="text"],
.resources-section input[type="email"],

.resources-section textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-left: -10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.resources-section select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.resources-section textarea {
  resize: vertical;
  height: 80px;
}

.resources-section select {
  background-color: #fff;
}

.resources-section fieldset {
  border: none;
  margin: 10px 0;
}

.resources-section fieldset label {
  display: block;
  font-size: 16px;
  margin: 5px 0;
}

.connect-button {
  width: 20%;
  background-color: #ff8c00; /* Orange button */
  color: white;
  padding: 12px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin-top: 15px;
  padding: 0.8rem 0.5rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.5s ease;
  
}

button.connect-button {
  width: 20%;
  background-color: #ff8c00; /* Orange button */
  color: white;
  padding: 12px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin-top: 0px;
  padding: 0.8rem 0.5rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top:15px;
  transition: background-color 0.3s, transform 0.5s ease;
  
}

.connect-button:hover {
  background-color: #e07b00; /* Darker orange on hover */
  transform: scale(1.1)
}

#results {
  margin-top: 15px;
}

.company {
  margin-top: 5px;
  font-size: 14px;
}

.company a {
  color: #0073e6;
  text-decoration: none;
}

.company a:hover {
  text-decoration: underline;
}

/* Optional Styling for Input Focus */
.resources-section input:focus,
.resources-section select:focus,
.resources-section textarea:focus {
  border-color: #ff8c00; /* Highlight input on focus */
  outline: none;
}

.text-green {
    color: #4CAF50;
    font-weight: bold;
}

.text-orange {
    color: #FFA500;
    font-weight: bold;
}


.dropdown-icon {
  display: none;
  font-size: 3rem; /* Increased icon size */
  cursor: pointer;
  margin-right: 20px;
}

.dropdown-menu {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0; /* Full width dropdown */
  background: #2583b9;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  animation: dropdown 0.3s ease-in-out;
  padding: 0rem 0;

}

.dropdown-menu ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin: 0;
  padding: 10px;
  position: absolute;
  right: 20px;
  top: 45px; /* Moved up */
  width: 180px;
  background-color: #E9EEF1;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Specific styling for subscribe and donate buttons */
.dropdown-menu ul .subscribe-btn,
.dropdown-menu ul .donate-btn {
    width: 100%; /* Slightly smaller than nav buttons */
    padding: 12px; /* Reduced padding */
    margin: 0 auto;
    font-size: 0.85rem; /* Slightly smaller font */
    text-align: center;
    margin-left: 10px;
}

.dropdown-menu ul li {
  text-align: center;
}

@keyframes dropdown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}



@media (max-width: 1100px) {
  .nav-links,
  .cta-buttons {
    display: none; /* Hide on small screens */
  }

  .dropdown-icon {
    display: block;
  }

  .nav-btn-green {
    background-color: #3a5d33;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 8px 20px;
    margin: 5px auto;
    width: 80%;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block;
    text-decoration: none;
    font-size: 0.9rem;
  }

  .nav-btn-orange {
    background-color: #FFA500;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 8px 20px;
    margin: 5px auto;
    width: 80%;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block;
    text-decoration: none;
    font-size: 0.9rem;
  }
}

/* Add responsive styles */
@media screen and (max-width: 1100px) {
    .mission h3 {
        font-size: calc(0.9rem + 0.3vw);
        line-height: 1.4;
        padding: 0 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    .mission {
        padding: 1rem 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mission h2 {
        font-size: 3rem; /* Keep original size */
        margin-bottom: 1rem;
        text-align: center;
        width: 100%;
    }

    .mission h3 {
        font-size: 1rem;
        line-height: 1.5;
        padding: 0;
        text-align: center;
        width: calc(100% - 16px);
        max-width: 100%;
        margin: 0 8px;
        display: block;
    }

    /* Make colored text inline with regular text */
    .text-green, .text-orange {
        display: inline;
        font-size: inherit;
    }
}

@media screen and (max-width: 1100px) {
    .resources-section {
        transform: scale(0.9);
        margin: -5% auto; /* Compensate for scale */
    }

    .input, .select, .textarea {
        font-size: 0.9rem;
        padding: 6px;
    }

    .label {
        font-size: 0.9rem;
        margin-bottom: 8px;
    }

    .fieldset {
        padding: 10px;
        margin: 10px 0;
    }

    .fieldset label {
        font-size: 0.9rem;
    }

    .connect-button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 800px) {
    .resources-section {
        transform: scale(0.85);
        margin: -7% auto; /* Further compensate for smaller scale */
    }

    .input, .select, .textarea {
        font-size: 0.85rem;
        padding: 5px;
    }

    .label {
        font-size: 0.85rem;
        margin-bottom: 6px;
    }

    .fieldset {
        padding: 8px;
        margin: 8px 0;
    }

    .fieldset label {
        font-size: 0.85rem;
    }

    .connect-button {
        padding: 6px 14px;
        font-size: 0.85rem;
    }
}

